import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiEdit, BiEditAlt } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { ProcedureModal } from "../utils/genericComponent";
import { AiOutlineDelete } from "react-icons/ai";
import { Button, Modal, notification, Skeleton } from "antd";
import SkeletonByTaskId from "../components/Skeleton/SkeletonByTaskId";

const ProceduresDetails = ({
  dataId,
  fetchProcedures,
  handleProcedureCancels,
}) => {
  const [procedure, setProcedure] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const [selectedHome, setSelectedHome] = useState(
    JSON.parse(localStorage.getItem("selectedHome")) || null
  );
  const [loading, setLoading] = useState(false);
  const openNotification = (placement, msg,status) => {
    {
      status === 200?api.success({
        description: msg,
        placement
      }):api.error({
        description: msg,
        placement
      });
    }
   
  };
  const fetchProcedureById = async (id) => {
    setLoading(true);
    const userData = localStorage.getItem("userData");
    const userDataObj = JSON.parse(userData);
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}procedures/${id}`,
      {
        headers: {
          Authorization: `Bearer ${userDataObj.token}`,
        },
      }
    );
    setLoading(false);
    setProcedure(response?.data?.data);
  };
  useEffect(() => {
    if (dataId) {
      fetchProcedureById(dataId);
    }
  }, [dataId]);
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Close Modal
  const handleProcedureCancel = () => {
    setIsModalVisible(false);
  };

  // Function to show the delete confirmation modal
  const showDeleteConfirmModal = () => {
    setIsDeleteModalVisible(true);
  };

  // Function to handle the cancel action in the delete modal
  const handleCancelDelete = () => {
    setIsDeleteModalVisible(false); // Close the modal
  };

  // Function to handle the delete action
  const handleConfirmDelete = () => {
    // Call the delete handler to delete the procedure
    handleDeleteProcedure(procedure.id); // Assuming 'id' is the identifier for the procedure
    setIsDeleteModalVisible(false); // Close the modal
    handleProcedureCancels();
  };
  const handleDeleteProcedure = async (procedureId) => {
    const userData = localStorage.getItem("userData");
    const userDataObj = JSON.parse(userData);
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}procedures/${procedureId}`,
      {
        headers: {
          Authorization: `Bearer ${userDataObj.token}`,
        },
      }
    );

    setIsModalVisible(false);
    fetchProcedures(selectedHome?.id);
  };
  return (
    <div className="text-start flex flex-col md:flex-row  py-4">
    {contextHolder}

      <ProcedureModal
        open={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onCancel={handleProcedureCancel}
        selectedHome={selectedHome}
        procedure={procedure}
        openNotification={openNotification}
        fetchProcedureById={fetchProcedureById}
        fetchProcedures={fetchProcedures}
      />
      {
        loading?<Skeleton/>:<div className="flex flex-col gap-4  w-[100%] md:px-4  px-1">
        <div className="flex justify-between items-center w-full flex-row break-all text-left gap-[18px]">
          <h1 className="text-[20px] font-bold">{procedure?.name}</h1>
          <div className="flex gap-4 flex-row cursor-pointer">
            <BiEditAlt size={24} color="#616161" onClick={showModal} />
            <AiOutlineDelete color="#E64646" size={24} onClick={showDeleteConfirmModal} />
          </div>
        </div>

        <h1 className="text-[18px] font-normal text-gray-600 ">Steps: </h1>
        <div className="flex flex-col gap-3 h-[400px] p-2 cards-scroll-container">
          {procedure?.steps?.map((step, stepIndex) => (
            <div key={stepIndex} className="flex items-center gap-2">
              <div className="h-[5px] w-[5px] rounded-full bg-[#205368] ring-[#205368] ring-[2px]"></div>
              <h1 className="text-[14px] font-normal text-gray-700">{step}</h1>
            </div>
          ))}
        </div>
      </div>
      }
      
      <Modal
        title="Are you sure you want to delete?"
        open={isDeleteModalVisible}
        onCancel={handleCancelDelete}
        footer={[
          <Button key="cancel" onClick={handleCancelDelete}>
            Cancel
          </Button>,
          <Button
            key="delete"
            type="primary"
            danger
            onClick={handleConfirmDelete}
          >
            Delete
          </Button>,
        ]}
      >
        <p>Once deleted, this procedure will be permanently removed.</p>
      </Modal>
    </div>
  );
};

export default ProceduresDetails;
