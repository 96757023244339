import React from "react";
import { IoPlayOutline } from "react-icons/io5";
import { GiCheckMark } from "react-icons/gi";
import dayjs from "dayjs";
import SkeletonByTaskId from "../../components/Skeleton/SkeletonByTaskId";
import { BsArrowRepeat } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { FiAlertTriangle } from "react-icons/fi";
import "./TaskCard.css";

const TaskCards = ({ taskCompleteOrIncomplete, loading, staffTasksName }) => {
  const params = useParams();
  const id = params.id;

  const navigate = useNavigate();

  return (
    <>
      <div className="task-container gap-5 px-5 pb-4">
        {taskCompleteOrIncomplete?.length !== 0 && (
          <>
            {taskCompleteOrIncomplete?.map((task, index) => {
              function formatTime(startTime) {
                if (!startTime) {
                  return "N/A";
                }
                const hours = Math.floor(startTime / 100); // Extract hours
                const minutes = startTime % 100; // Extract minutes
                const period = hours >= 12 ? "PM" : "AM"; // Determine AM/PM
                const formattedHours = hours % 12; // Convert to 12-hour format
                const formattedMinutes = minutes.toString().padStart(2, "0"); // Add leading zero if needed

                return `${formattedHours}:${formattedMinutes} ${period}`;
              }

              const taskStartDate = new Date(task?.taskDate);
              const todayDate = new Date();

              // Calculate the difference in time (in milliseconds) and convert it to days
              const timeDifference = todayDate - taskStartDate;
              const daysDifference = Math.floor(
                timeDifference / (1000 * 3600 * 24)
              );
              return (
                <div
                  key={index}
                  onClick={() => {
                    if (staffTasksName === "staff-tasks") {
                      navigate(`/staff-tasks/${id}/${task?.id}`,{
                        state: {
                          startTime: formatTime(task?.startTime),
                          endTime:formatTime(task?.endTime)
                        }});
                    } else {
                      navigate(`/my-tasks/${id}/${task?.id}`);
                    }
                  }}
                  className={`h-[120px] flex flex-col justify-between relative px-3 py-3  max-w-[500px] w-[100%] rounded-xl ${
                    task?.taskStatus === "complete"
                      ? "bg-[#E8F8FF]"
                      : task?.taskStatus.toLowerCase() === "pending" || task?.taskStatus.toLowerCase() === "scheduled"
                      ? "bg-[#FFF9F0]"
                      : "bg-[#FFECEC]"
                  } cursor-pointer  hover:shadow-md transition-all duration-200`}
                >
                  <div
                    className={`absolute top-1/2 left-0 w-[4px] h-[70%] ${
                      task?.taskStatus === "complete"
                        ? "bg-[#205368]"
                        : task?.taskStatus.toLowerCase() === "pending" || task?.taskStatus.toLowerCase() === "scheduled"
                        ? "bg-[#DE9C36]"
                        : "bg-[#E64646]"
                    } rounded-full transform -translate-y-1/2`}
                  ></div>
                  <div className="flex justify-between items-center">
                    <div className="flex flex-col gap-2 justify-center items-start">
                      <h1 className="text-[18px] flex items-center gap-2">
                        {task?.title
                          ? task?.title?.length > 15
                            ? `${task?.title?.substring(0, 15)}...`
                            : task?.title
                          : "N/A"}

                        {task?.taskStatus === "overdue" && (
                          <FiAlertTriangle size={18} className="text-red-600" />
                        )}
                      </h1>
                      <h1 className="text-[15px] font-normal text-gray-700">
                        Start time:{" "}
                        {formatTime(task?.startTime)}
                      </h1>
                    </div>
                    <div className="flex mt-auto ">
                      <h1 className="text-[15px] font-normal text-gray-700">
                        End time: {formatTime(task?.endTime)}
                      </h1>
                    </div>
                  </div>

                  <div className="flex justify-between items-center">
                    <div className="flex flex-row gap-2 justify-center items-center">
                      <h1
                        className={`w-[12px] h-[12px] rounded-full ${
                          task?.taskStatus === "complete"
                            ? "bg-[#205368]"
                            : task?.taskStatus.toLowerCase() === "pending" || task?.taskStatus.toLowerCase() === "scheduled"
                            ? "bg-[#DE9C36]"
                            : "bg-[#E64646]"
                        }`}
                      ></h1>
                      <h1
                        className={`text-[18px] font-normal flex items-center ${
                          task?.taskStatus === "complete"
                            ? "text-[#205368]"
                            : task?.taskStatus.toLowerCase() === "pending" || task?.taskStatus.toLowerCase() === "scheduled"
                            ? "text-[#DE9C36]"
                            : "text-[#E64646]"
                        } capitalize`}
                      >
                        {task?.taskStatus.toLowerCase() === "scheduled"?"Pending":task?.taskStatus}
                        {task?.taskStatus !== "complete" &&
                          daysDifference > 0 &&
                          ` for ${daysDifference} Days`}
                        {task.taskType === "RECURRING" && (
                          <>
                            {" "}
                            {" | "}
                            <BsArrowRepeat className="ml-2" size={22} />
                          </>
                        )}
                      </h1>
                    </div>
                    {/* <div className="flex gap-3">
                      <button
                        // onClick={() => {
                        //   navigate(`/add-tasks`);
                        // }}
                        className="font-medium rounded-full flex items-center justify-center bg-[#205368] hover:bg-slate-500  w-7 h-7"
                      >
                        <IoPlayOutline lus size={20} className="text-white" />
                      </button>
                      {task?.taskStatus !== "complete" && (
                        <button
                          disabled
                          // onClick={() => {
                          //   navigate(`/add-tasks`);
                          // }}
                          className="font-medium rounded-full flex items-center justify-center disabled:bg-[#2A5241] bg-[#2A5241] hover:bg-slate-500  w-7 h-7"
                        >
                          <GiCheckMark lus size={16} className="text-white" />
                        </button>
                      )}
                    </div> */}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      {loading && <SkeletonByTaskId />}
    </>
  );
};

export default TaskCards;
