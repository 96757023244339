import { BsArrowRepeat } from "react-icons/bs";
import { IoPlayOutline } from "react-icons/io5";
import SkeletonByTaskId from "../Skeleton/SkeletonByTaskId";
import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Input, Modal, notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import ProceduresDetails from "../../pages/ProceduresDetails";
import SkeletonAllTask from "../Skeleton/Skeleton";
import { ProcedureModal } from "../../utils/genericComponent";

export const Procedures = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalCreateVisible, setIsModalCreateVisible] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement, msg,status) => {
    {
      status === 200?api.success({
        description: msg,
        placement
      }):api.error({
        description: msg,
        placement
      });
    }
   
  };
  const [dataId, setDataId] = useState(null);
  const [procedure, setProcedure] = useState([]);
  const [selectedHome, setSelectedHome] = useState(
    JSON.parse(localStorage.getItem("selectedHome")) || null
  );
  const fetchProcedure = async (homeId) => {
    setLoading(true);
    const userData = localStorage.getItem("userData");
    const userDataObj = JSON.parse(userData);
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}procedures?homeId=${homeId}`,
      {
        headers: {
          Authorization: `Bearer ${userDataObj.token}`,
        },
      }
    );
    setLoading(false);
    setProcedure(response.data.data);
  };
  useEffect(() => {
    if (selectedHome) {
      fetchProcedure(selectedHome.id);
    }
  }, []);

  // Close Modal
  const handleProcedureCancel = () => {
    setIsModalVisible(false);
    setIsModalCreateVisible(false)
    setDataId(null);
  };
  const setSearchQuery = async (id,value) => {

    const userData = localStorage.getItem("userData");
    const userDataObj = JSON.parse(userData);
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}procedures?homeId=${id}&searchText=${value}`,
      {
        headers: {
          Authorization: `Bearer ${userDataObj.token}`,
        },
      }
    );

    setProcedure(response?.data?.data);
  };
  const showModal = () => {
    setIsModalCreateVisible(true);
  };
  return (
    <>
    {contextHolder}
     {loading? <SkeletonAllTask />:
     <div className="gap-5  pb-4 mt-4">
               <div className="flex items-center px-5 mb-4">
    {/* Search Bar */}
    <input
      type="text"
      placeholder="Search procedures..."
      className="px-4 py-2 w-full max-w-[300px] border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
      onChange={(e) => setSearchQuery(selectedHome?.id,e.target.value)} // Add search handling
    />
    
    {/* Create Procedure Button */}
    <Button
      style={{
        backgroundColor: "#205368",
        color: "#FFF",
        padding: "10px 20px",
        borderRadius: "8px",
        marginLeft: "10px",
        height:"40px"
      }}
      onClick={showModal} 
    >
      Create Procedure
    </Button>
  </div>
  
     <div className="task-container gap-5 px-5 pb-4 mt-4 for-completed-task rounded-3xl">
           <ProcedureModal
                open={isModalCreateVisible}
                setIsModalVisible={setIsModalCreateVisible}
                onCancel={handleProcedureCancel}
                selectedHome={selectedHome}
                fetchProcedure={fetchProcedure}
                openNotification={openNotification}
              />
 
        <Modal
          open={isModalVisible}
          bodyStyle={{maxHeight:"70vh"}}
          onCancel={handleProcedureCancel}
          footer={[
            <Button key="back" onClick={handleProcedureCancel}>
              Cancel
            </Button>,
          ]}
        >
          <div>
            <h1 className="text-[20px] font-bold">Procedures</h1>
            <ProceduresDetails
              dataId={dataId}
              fetchProcedures={fetchProcedure}
              handleProcedureCancels={handleProcedureCancel}
            />
          </div>
        </Modal>
        {procedure?.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => {
              
                setDataId(item?.id);
                setIsModalVisible(true);
              }}
              className={`bg-[#E8F8FF] flex flex-col  relative px-3 py-3  max-w-[500px] w-[100%] rounded-xl cursor-pointer  hover:shadow-md transition-all duration-200`}
            >
              <div
                className={`absolute top-1/2 left-0 w-[4px] h-[70%]  rounded-full transform -translate-y-1/2 bg-[#205368]`}
              ></div>
              <div className="flex justify-between items-center">
                <div className="flex flex-col gap-2 justify-center items-start break-all text-left">
                  <h1 className="text-[18px] flex items-center gap-2">
                    {item?.name}
                  </h1>
                  <h1 className="text-[15px] font-normal text-gray-700">
                    Description
                  </h1>
                </div>
              </div>
              <div className="flex flex-col gap-3 mt-3">
                {item?.steps?.slice(0, 2).map((step, stepIndex) => (
                  <div
                    key={stepIndex}
                    className="flex items-center gap-2 break-all text-left"
                  >
                    <div className="h-[5px] w-[5px] rounded-full bg-[#205368] ring-[#205368] ring-[2px]"></div>
                    <h1 className="text-[14px] font-normal text-gray-700">
                      {step}
                    </h1>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      </div>}
    
     
    </>
  );
};
