import React, { useEffect, useState } from "react";
import "../../styles/AddTask.css";
import { useNavigate } from "react-router-dom";
import { CgDanger } from "react-icons/cg";

import axios from "axios";
import {
  DatePicker,
  Input,
  Select,
  Upload,
  notification,
  message,
  Button,
  Tooltip,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import {
  frequencyMapping,
  NthDaysOfWeek,
  reverseFrequencyMapping,
  taskSchedule,
  WeekDays,
} from "../../utils/taskSchedule";
import {
  Custom,
  Daily,
  EveryWorkDay,
  Monthly,
  Weekly,
  Yearly,
} from "./Frequency";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { ReactComponent as DownArrow } from "../../images/down-arrow.svg";
import { ReactComponent as Calender } from "../../images/calendar-icon.svg";
import { ReactComponent as TimeIcon } from "../../images/time-icon.svg";
import TimeConflictModal, {
  ProcedureModal,
} from "../../utils/genericComponent";
import moment from "moment";

const AddTask = () => {
  const [api, contextHolder] = notification.useNotification();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleIconClick = () => {
    setTooltipVisible(!tooltipVisible); // Toggle tooltip visibility
  };
  const [loadingApi, setLoadingApi] = useState(false);
  const [loading, setLoading] = useState(false);
  const openNotification = (placement, msg, status) => {
    {
      status === 200
        ? api.success({
            description: msg,
            placement,
          })
        : api.error({
            description: msg,
            placement,
          });
    }
  };
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [procedure, setProcedure] = useState([]);
  const [fileLists, setFileLists] = useState({});
  const [staffList, setStaffList] = useState([]);
  const [taskErrorMsg, setTaskErrorMsg] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [scheduleText, setScheduleText] = useState({});
  const [errorTasks, setErrorTasks] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", body: "" });
  const [radioSelections, setRadioSelections] = useState({});
  const [selectedHome, setSelectedHome] = useState(
    JSON.parse(localStorage.getItem("selectedHome")) || null
  );
  const [isModalVisible, setIsModalVisible] = useState(false); // For modal visibility
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
    </button>
  );
  const handleCancel = () => {
    setOpen(false);
    setModalContent({ title: "", body: "" });
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Close Modal
  const handleProcedureCancel = () => {
    setIsModalVisible(false);
  };

  // const handleRedirect = () => {
  //   setLoading(true);
  //   setOpenTask(true)
  //   setLoading(false);
  // };
  const [taskData, setTaskData] = useState([
    {
      homeId: null,
      title: "",
      description: "",
      procedureId: null,
      procedureSteps: [],
      assignedToUserId: null,
      assignedToSubUserId: null,
      taskFrequency: "DOES_NOT_REPEAT",
      locationAddress: "",
      latitude: null,
      longitude: null,
      taskPhotos: [],
      taskSchedule: [
        {
          startDate: dayjs().format("DD-MM-YYYY"), // Date string, required
          endDate: null, // Date string, optional, nullable
          startTime: null, // Optional, nullable number
          endTime: null, // Optional, nullable number
          interval: 1, // Optional number, default 1
          onWeekDays: ["MONDAY"], // Optional array of enums, default []
          onMonths: ["JANUARY"], // Optional array of enums, max 1, default []
          onDays: [1], // Optional array of numbers, max 1, default []
          onNthDaysOfWeek: [1], // Optional array of numbers, max 1, default []
          frequency: null,
        },
      ],
      isCompletionRequired: false,
      isCompletionPictureRequired: false,
    },
    {
      homeId: null,
      title: "",
      description: "",
      procedureId: null,
      procedureSteps: [],
      assignedToUserId: null,
      assignedToSubUserId: null,
      taskFrequency: "DOES_NOT_REPEAT",
      locationAddress: "",
      latitude: null,
      longitude: null,
      taskPhotos: [],
      taskSchedule: [
        {
          startDate: dayjs().format("DD-MM-YYYY"), // Date string, required
          endDate: null, // Date string, optional, nullable
          startTime: null, // Optional, nullable number
          endTime: null, // Optional, nullable number
          interval: 1, // Optional number, default 1
          onWeekDays: ["MONDAY"], // Optional array of enums, default []
          onMonths: ["JANUARY"], // Optional array of enums, max 1, default []
          onDays: [1], // Optional array of numbers, max 1, default []
          onNthDaysOfWeek: [1], // Optional array of numbers, max 1, default []
          frequency: null,
        },
      ],
      isCompletionRequired: false,
      isCompletionPictureRequired: false,
    },
  ]);


  const fetchProcedure = async (homeId) => {
    const userData = localStorage.getItem("userData");
    const userDataObj = JSON.parse(userData);
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}procedures?homeId=${homeId}`,
      {
        headers: {
          Authorization: `Bearer ${userDataObj.token}`,
        },
      }
    );
    setProcedure(response.data.data);
  };

  const fetchStaff = async (homeId) => {
    const userData = localStorage.getItem("userData");
    const userDataObj = JSON.parse(userData);
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}homes/${homeId}/members?role=Staff`,
      {
        headers: {
          Authorization: `Bearer ${userDataObj.token}`,
        },
      }
    );
    setStaffList(response.data.data);
  };

  // commenting for test
  // useEffect(() => {
  //   // Fetch selected home from localStorage

  //   if (selectedHome) {
  //     setTaskData((prevTaskData) => ({
  //       ...prevTaskData,
  //       homeId: selectedHome.id, // Set homeId dynamically
  //     }));
  //   } else {
  //     console.error("No home selected!");
  //     navigate("/");
  //     return;
  //     // Redirect user to select a home if none is found
  //   }
  // }, [navigate, selectedHome]);

  useEffect(() => {
    if (selectedHome) {
      setTaskData((prevTaskData) =>
        prevTaskData.map((task) => ({
          ...task,
          homeId: selectedHome.id, // Set homeId dynamically for each task
        }))
      );
    } else {
      console.error("No home selected!");
      navigate("/");
      return; // Redirect user to select a home if none is found
    }
  }, [navigate, selectedHome]);

  useEffect(() => {
    const updateHomeData = async () => {
      if (taskData[0].homeId) {
        setLoading(true); // Start loading

        try {
          await Promise.all([
            fetchProcedure(taskData[0]?.homeId),
            fetchStaff(taskData[0]?.homeId),
          ]);
        } catch (error) {
          console.error("Error updating home data:", error);
        } finally {
          setLoading(false); // End loading
        }
      }
    };

    updateHomeData();
  }, [taskData[0].homeId]);

  const updateScheduleText = (index) => {
    if (!taskData.length) {
      setScheduleText({}); // Handle empty taskData
      return;
    }

    const task = taskData[index];
    // Assuming you're working with the first task
    const schedule = task.taskSchedule?.[0]; // Assuming single schedule
    if (!schedule) {
      setScheduleText((prev) => ({ ...prev, [index]: "" })); // Handle missing schedule
      return;
    }

    const frequency = schedule.frequency; // e.g., Monthly, Yearly, etc.
    const onDay = schedule.onDays?.[0];
    const nthDay = schedule.onNthDaysOfWeek?.[0];
    const weekDay = schedule.onWeekDays?.[0];
    const weekDays = schedule.onWeekDays || [];
    const interval = schedule.interval;
    
    const month = schedule.onMonths?.[0];
    const formattedDate =schedule?.startDate? moment(schedule?.startDate, "DD-MM-YYYY").format(
      "DD MMM YYYY"
    ):"";
    const formattedEndDate = schedule?.endDate
      ? moment(schedule?.endDate, "DD-MM-YYYY").format("DD MMM YYYY")
      : "";
    let newScheduleText = "";

    const selectedRadio = radioSelections[index] || "On Day";
    if(frequency === "DAY"){
     newScheduleText =formattedDate? `Occurs every ${interval && interval!==1 ? interval :""} day starting from ${formattedDate} ${formattedEndDate? `to ${formattedEndDate}`:``}`:"";

    }
    if (frequency === "EVERY_WORK_DAY") {
      newScheduleText =formattedDate? `Occurs every work day starting from ${formattedDate} ${formattedEndDate? `to ${formattedEndDate}`:``}`:"";

    }
    if (frequency === "WEEK") {
      const weekDaysText = weekDays
      .map((day) => {
        return day.charAt(0).toUpperCase() + day.slice(1).toLowerCase();
      })
      .join(", ");
    newScheduleText =formattedDate? `Occurs every ${interval && interval!==1 ? interval :""} week on ${weekDaysText} starting from ${formattedDate} ${formattedEndDate? `to ${formattedEndDate}`:``}`:"";
    }

    if (selectedRadio === "On Day") {
      if (frequency === "MONTH") {
        newScheduleText =formattedDate? `Occurs on ${`day ${onDay? onDay : ""} of every ${interval && interval!==1 ? interval :""} month`} starting from ${formattedDate} ${formattedEndDate ? ` to ${formattedEndDate}` : ''}`:"";

      } else if (frequency === "YEAR") {
        newScheduleText =formattedDate? `Occurs  every ${interval && interval!==1 ? interval :""} year on ${`day ${onDay? onDay : ""} of ${month.charAt(0).toUpperCase() + month.slice(1).toLowerCase()}`} starting from ${formattedDate} ${formattedEndDate? `to ${formattedEndDate}`:``}`:"";

      }
    } else if (selectedRadio === "On The") {
      const nthDayText =
        NthDaysOfWeek.find((item) => item.value === nthDay)?.label || "first";
      const weekDayText =
        WeekDays.find((item) => item.value === weekDay)?.label || "MONDAY";

      if (frequency === "MONTH") {
        newScheduleText = formattedDate?`Occurs on ${ `the ${nthDayText} ${weekDays} of every ${interval && interval!==1 ? interval :""} month`} starting from ${formattedDate} ${formattedEndDate ? ` to ${formattedEndDate}` : ''}`:"";

      } else if (frequency === "YEAR") {
        newScheduleText =formattedDate? `Occurs  every ${interval && interval!==1 ? interval :""} year on the ${nthDayText} ${weekDays} of ${month.charAt(0).toUpperCase() + month.slice(1).toLowerCase()}   starting from ${formattedDate} ${formattedEndDate? `to ${formattedEndDate}`:``}`:"";

      }
    }
    if (frequency === "CUSTOM") {
      const formattedDate = task?.taskSchedule[0].startDate? moment(task?.taskSchedule[0].startDate, "DD-MM-YYYY").format(
        "DD MMM YYYY"
      ):"";
      const formattedEndDate = task?.taskSchedule[task?.taskSchedule.length - 1]
        .startDate
        ? moment(
            task?.taskSchedule[task?.taskSchedule.length - 1].startDate,
            "DD-MM-YYYY"
          ).format("DD MMM, YYYY")
        : "";
    
      newScheduleText = formattedDate
  ? `Occurs on ${formattedDate}${formattedEndDate ? `, ${formattedEndDate}` : ""}`
  : "";
    }
    setScheduleText((prev) => ({
      ...prev,
      [index]: newScheduleText, // Map text to the task index
    }));
  };

  // useEffect(() => {
  //   updateScheduleText();
  // }, [radioSelections]);

  const handleInputChange = (
    value,
    name,
    index,
    nestedField,
    scheduleIndex = 0
  ) => {
    setTaskData((prev) => {
      const updatedData = [...prev]; // Create a copy of the taskData array
      const overTask = updatedData[index];
      // Ensure that the index exists within taskData
      if (index !== null && updatedData[index]) {
        const task = updatedData[index]; // Access the specific task by index
const newErrors = {}
newErrors[index] = {
  assignedToUserId: false
};
        if (name === "procedureId") {
          const selectedProcedure = procedure.find((item) => item.id === value);
          const stepsAsText = selectedProcedure?.steps || [];

          // Update the procedureSteps of the specific task
          task.procedureSteps = stepsAsText;
        }
        if (name === "assignedToUserId") {
          const assignedStaff = staffList.find(
            (staff) => staff.userId === value
          );

          // If staff is found and has work hours
          if (assignedStaff && assignedStaff.staffWorkHour?.length > 0) {
            const workDays = assignedStaff.staffWorkHour[0]?.workDays || [];
            task.taskSchedule[0].onWeekDays = workDays; // Update workdays
          }
        }

        if (name === "taskFrequency") {
          if (!task.assignedToUserId || task.assignedToUserId.length === 0) {
            setTaskErrors((prevErrors) => ({
              ...prevErrors,
              [index]: {
                ...prevErrors[index],
                assignedToUserId: true, // Set the error for assignedToUserId to true
              },
            }));
            return prev; // Exit without updating taskData
          }

          setRadioSelections((prev) => ({
            ...prev,
            [index]: "On Day",
          }));

          task.taskSchedule.forEach((schedule) => {
            schedule.onDays = [1];
            schedule.onMonths = ["JANUARY"];
            schedule.onNthDaysOfWeek = [1];
            schedule.onWeekDays = ["MONDAY"];
          });
          if (value === "EVERY_WORK_DAY") {
            if (!task.assignedToUserId || task.assignedToUserId.length === 0) {
              api.info({
                description: "Please select an assigned First",
                placement: "top",
                duration: 3,
              });
              return prev; // Return the previous state without updating
            }

            const assignedStaff = staffList.find(
              (staff) => staff.userId === task.assignedToUserId
            );

            if (assignedStaff && assignedStaff.staffWorkHour?.length > 0) {
              const workDays = assignedStaff.staffWorkHour[0]?.workDays || [];
             

              task.taskSchedule[0].onWeekDays = workDays; // Update workDays
            }
          }
          if (value !== "CUSTOM") {
            task.taskSchedule = task.taskSchedule.slice(0, 1); // Retain only the first schedule
          }
          task.taskFrequency = value;
      
          const mappedFrequency = frequencyMapping[value] || value;
          task.taskSchedule[0].frequency = mappedFrequency;
        } else if (
          name === "taskSchedule" &&
          nestedField === "frequency" &&
          index !== null
        ) {
          setRadioSelections((prev) => ({
            ...prev,
            [index]: "On Day",
          }));

          task.taskSchedule.forEach((schedule) => {
            schedule.onDays = [1];
            schedule.onMonths = ["JANUARY"];
            schedule.onNthDaysOfWeek = [1];
            schedule.onWeekDays = ["MONDAY"];
          });
          if (value === "EVERY_WORK_DAY") {
            if (!task.assignedToUserId || task.assignedToUserId.length === 0) {
              api.info({
                description: "Please select an assigned First",
                placement: "top",
                duration: 3,
              });
              return prev; // Return the previous state without updating
            }

            const assignedStaff = staffList.find(
              (staff) => staff.userId === task.assignedToUserId
            );

            if (assignedStaff && assignedStaff.staffWorkHour?.length > 0) {
              const workDays = assignedStaff.staffWorkHour[0]?.workDays || [];
            
              task.taskSchedule[0].onWeekDays = workDays; // Update workDays
            }
          }
          task.taskSchedule[scheduleIndex][nestedField] = value;
          const mappedFrequency = reverseFrequencyMapping[value] || value;

          task.taskFrequency = mappedFrequency;
        } else if (
          name === "assignedToUserId" &&
          value === task.assignedToSubUserId
        ) {
          task[name] = value;
          task.assignedToSubUserId = null;
        } else if (
          name === "assignedToSubUserId" &&
          value === task.assignedToUserId
        ) {
          return updatedData;
        }

        if (index !== null && nestedField) {
          // Always work with the first element of taskSchedule

          if (nestedField === "startTime" || nestedField === "endTime") {
            if (value) {
              const [hours, minutes] = value.split(":");
              const timeAsNumber =
                parseInt(hours, 10) * 100 + parseInt(minutes, 10);

              if (task.taskSchedule[scheduleIndex]) {
                task.taskSchedule[scheduleIndex][nestedField] = timeAsNumber;
              } else {
                console.error(
                  `taskSchedule[${scheduleIndex}] is undefined for taskIndex: ${index}`
                );
              }
            } else {
              task.taskSchedule[scheduleIndex][nestedField] = null;
            }
          } else {
            if (task.taskSchedule[scheduleIndex]) {
              task.taskSchedule[scheduleIndex][nestedField] = value;
            } else {
              console.error(
                `taskSchedule[${scheduleIndex}] is undefined for taskIndex: ${index}`
              );
            }
          }
        } else {
          task[name] = value;
        }
      } else {
        // Handle the case where the index is invalid or undefined
        console.error("Invalid index:", index);
      }

      // Remove the error for this field in the errors state
      setTaskErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };

        if (updatedErrors[index]) {
          const frequencyErrorMap = {
            CUSTOM: "customStartDate",
            YEARLY: "yearlyStartDate",
            MONTHLY: "monthlyStartDate",
            WEEKLY: "weeklyStartDate",
            DAILY: "dailyStartDate",
            EVERY_WORK_DAY: "everyStartDate",
          };

          const errorField = frequencyErrorMap[overTask.taskFrequency];

          // Delete the error field based on task frequency
          if (errorField && updatedErrors[index]?.[errorField]) {
            delete updatedErrors[index][errorField];
          } else if (nestedField) {
            delete updatedErrors[index][nestedField];
          } else {
            delete updatedErrors[index][name];
          }

          // Remove the error object if no errors remain
          if (Object.keys(updatedErrors[index]).length === 0) {
            delete updatedErrors[index];
          }
        } else {
          console.warn(`No error object found for index: ${index}`);
        }

        return updatedErrors;
      });

      return updatedData; // Return the updated array with the modified task
    });

    // updateScheduleText();
  };

  const addNewSchedule = (taskIndex) => {
    setTaskData((prevState) =>
      prevState.map((task, index) =>
        index === taskIndex
          ? {
              ...task,
              taskSchedule: [
                ...task.taskSchedule,
                {
                  startDate: null,
                  endDate: null,
                  startTime: null,
                  endTime: null,
                },
              ],
            }
          : task
      )
    );
  };
  const [taskErrors, setTaskErrors] = useState({});


  const handleSubmit = async (e, action) => {
    e.preventDefault();
    // Add your submission logic here
    let successfulTasks = [];
    let failedTasks = [];
    try {
      const userData = localStorage.getItem("userData");
      const userDataObj = JSON.parse(userData);
      const newErrors = {}; // Temporary object to collect errors

      for (let i = 0; i < taskData.length; i++) {
        const task = taskData[i];
        // Deep copy the task object to avoid mutating the original state
        const taskToSend = JSON.parse(JSON.stringify(task));

        newErrors[i] = {
          title: false,
          assignedToUserId: false,
          startDate: false,
          customStartDate: false,
          yearlyStartDate: false,
          monthlyStartDate: false,
          weeklyStartDate: false,
          dailyStartDate: false,
          everyStartDate: false,
          onWeekDays:false,
          onDays:false
        };

        // Check for required fields
        if (!taskToSend.title) {
          newErrors[i].title = true;
          failedTasks.push(i);
        }

        if (!taskToSend.assignedToUserId) {
          newErrors[i].assignedToUserId = true;
          failedTasks.push(i);
        }

        // Map taskFrequency to its respective error field
        const frequencyErrorMap = {
          DOES_NOT_REPEAT: "startDate",
          CUSTOM: "customStartDate",
          YEARLY: "yearlyStartDate",
          MONTHLY: "monthlyStartDate",
          WEEKLY: "weeklyStartDate",
          DAILY: "dailyStartDate",
          EVERY_WORK_DAY: "everyStartDate",
        };

        if (taskToSend?.taskFrequency) {
          const errorField = frequencyErrorMap[taskToSend.taskFrequency];
  if (errorField) {
    // Check all schedules for missing startDate
    taskToSend.taskSchedule.forEach((schedule, scheduleIndex) => {
      if (!schedule.startDate || schedule.startDate === "") {
        // Set error for the specific schedule index
        if (!newErrors[i][errorField]) {
          newErrors[i][errorField] = [];
        }
        newErrors[i][errorField][scheduleIndex] = true; // Track errors by index
        failedTasks.push(i);
      }
    });
  }
  if (taskToSend.taskFrequency !== "DOES_NOT_REPEAT" && (!taskToSend.taskSchedule[0]?.interval || taskToSend.taskSchedule[0].interval ==="")) {
    newErrors[i].interval = true; // Add error for missing or invalid interval
    failedTasks.push(i);
  } else {
    newErrors[i].interval = false; // No error for interval if valid
  }
        }
        if(!taskToSend.taskSchedule[0].onWeekDays.length>0){
          newErrors[i].onWeekDays = true;
          failedTasks.push(i);
        }

     
        // Adjust the task data based on its frequency
        if (taskToSend.taskFrequency === "CUSTOM") {
          taskToSend.taskSchedule.forEach((schedule) => {
            schedule.onDays = [];
            schedule.onMonths = [];
            schedule.onNthDaysOfWeek = [];
            schedule.onWeekDays = [];
            schedule.frequency = "CUSTOM";
          });
        }

        if (taskToSend.taskFrequency === "MONTHLY") {
          if (radioSelections[i] === "On Day") {
            taskToSend.taskSchedule.forEach((schedule) => {
              schedule.onNthDaysOfWeek = [];
              schedule.onWeekDays = [];
              schedule.onMonths = [];
            });
            if(!taskToSend.taskSchedule[0].onDays.length>0){
              newErrors[i].onDays = true;
              failedTasks.push(i);
            }
          } else if (radioSelections[i] === "On The") {
            taskToSend.taskSchedule.forEach((schedule) => {
              schedule.onDays = [];
              schedule.onMonths = [];
            });
          }
        }

        if (taskToSend.taskFrequency === "YEARLY") {
          if (radioSelections[i] === "On Day") {
            taskToSend.taskSchedule.forEach((schedule) => {
              schedule.onWeekDays = [];
              schedule.onNthDaysOfWeek = [];
            });
            if(!taskToSend.taskSchedule[0].onDays.length>0){
              newErrors[i].onDays = true;
              failedTasks.push(i);
            }
          } else if (radioSelections[i] === "On The") {
            taskToSend.taskSchedule.forEach((schedule) => {
              schedule.onDays = [];
            });
          }
        }

        if (
          taskToSend.taskFrequency === "WEEKLY" ||
          taskToSend.taskFrequency === "EVERY_WORK_DAY"
        ) {
          taskToSend.taskSchedule = taskToSend.taskSchedule.map((schedule) => ({
            ...schedule,
            onDays: [],
            onMonths: [],
            onNthDaysOfWeek: [],
          }));
        }
        if (taskToSend.taskFrequency === "DAILY") {
          taskToSend.taskSchedule = taskToSend.taskSchedule.map((schedule) => ({
            ...schedule,
            onDays: [],
            onMonths: [],
            onNthDaysOfWeek: [],
            onWeekDays: [],
          }));
        }
        if (taskToSend.taskFrequency === "DOES_NOT_REPEAT") {
          taskToSend.taskSchedule = taskToSend.taskSchedule.map((schedule) => ({
            ...schedule,
            onDays: [],
            onMonths: [],
            onNthDaysOfWeek: [],
            onWeekDays: [],
          }));
          taskToSend.isCompletionRequired = false;
        }
  
        if (failedTasks.includes(i)) {
          // Skip processing this task if it has errors
          continue;
        }

        try {
          // Send the task object to the API

          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}tasks`, // API endpoint
            taskToSend, // Data to send in the request body
            {
              headers: {
                Authorization: `Bearer ${userDataObj.token}`, // Authorization header
              },
            }
          );
          openNotification("top", `Task ${i + 1} Created Successfully`, 200);

          successfulTasks.push(i);
        } catch (taskError) {
          if (
            taskError?.response?.data?.data?.error?.includes(
              "Task timings conflicting"
            )
          ) {
            const conflictingTaskId =
              taskError.response.data.data.conflictingTaskIds[0];
            const staffId = taskToSend?.assignedToUserId;
            setModalContent({
              title: "Task Timing Conflict",
              body: `A conflict has been detected with another task (ID: ${conflictingTaskId}). Please review and resolve the conflict.`,
              conflictingTaskId,
              staffId,
            });
            setOpen(true);
          } else {
            console.error(
              `Error submitting task ${taskToSend.title}:`,
              taskError
            );
            failedTasks.push(i);
            // Notify the user about the specific task error
            openNotification(
              "top",
              taskError?.response?.data?.error ||
                taskError?.response?.data?.data?.error ||
                `Failed to submit task ${taskToSend.title}.`
            );
          }
        }
      }
      setFileLists([]);
      setTaskData((prevData) =>
        prevData.map((task, index) => {
          if (successfulTasks.includes(index)) {
            // Reset successful tasks
            return {
              homeId: selectedHome?.id,
              title: "",
              description: "",
              procedureId: null,
              procedureSteps: [],
              assignedToUserId: null,
              assignedToSubUserId: null,
              taskFrequency: "DOES_NOT_REPEAT",
              locationAddress: "",
              latitude: null,
              longitude: null,
              taskPhotos: [],
              taskSchedule: [
                {
                  startDate: dayjs().format("DD-MM-YYYY"),
                  endDate: null,
                  startTime: null,
                  endTime: null,
                  interval: 1,
                  onWeekDays: ["MONDAY"],
                  onMonths: ["JANUARY"],
                  onDays: [1],
                  onNthDaysOfWeek: [1],
                  frequency: null,
                },
              ],
              isCompletionRequired: false,
              isCompletionPictureRequired: false,
            };
          } else {
            // Keep failed tasks as they are
            return task;
          }
        })
      );
      setErrorTasks(successfulTasks);
      if (failedTasks.length > 0) {
        // Scroll to the first failed task

        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        if (action === "create") {
          // Redirect to "my-list" page
          setTimeout(() => {
            navigate("/staff-tasks");
          }, 2000);
        } else if (action === "addMore") {
          // Reset all tasks for new entry
          setTimeout(() => {
            setFileLists([]);
            setTaskData((prevData) =>
              prevData.map(() => ({
                homeId: selectedHome?.id,
                title: "",
                description: "",
                procedureId: null,
                procedureSteps: [],
                assignedToUserId: null,
                assignedToSubUserId: null,
                taskFrequency: "DOES_NOT_REPEAT",
                locationAddress: "",
                latitude: null,
                longitude: null,
                taskPhotos: [],
                taskSchedule: [
                  {
                    startDate: dayjs().format("DD-MM-YYYY"),
                    endDate: null,
                    startTime: null,
                    endTime: null,
                    interval: 1,
                    onWeekDays: ["MONDAY"],
                    onMonths: ["JANUARY"],
                    onDays: [1],
                    onNthDaysOfWeek: [1],
                    frequency: null,
                  },
                ],
                isCompletionRequired: false,
                isCompletionPictureRequired: false,
              }))
            );
          }, 2000);
        }
      }
      setTaskErrors(newErrors);
    } catch (error) {
      setTaskErrorMsg(
        error?.response?.data?.error || error?.response?.data?.data?.error
      );
      openNotification(
        "top",
        error?.response?.data?.data?.error ||
          error?.response?.data?.error ||
          "Something went wrong."
      );
    }
  };

  // const validateFields = () => {
  //   let isValid = true;

  //   if (!taskData.title.trim()) {
  //     openNotification("top", "Enter a valid title.");
  //     isValid = false;
  //   } else if (!taskData.assignedToUserId) {
  //     openNotification("top", "Select an assignee.");
  //     isValid = false;
  //   }

  //   return isValid;
  // };

  // Store fileLists per task

  const handleChange =
    (index) =>
    ({ fileList: newFileList }) => {
      // Update fileList for the specific task
      setFileLists((prev) => ({
        ...prev,
        [index]: newFileList,
      }));
    };

  const customUpload = async ({ file, onSuccess, onError, filename }) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const userData = localStorage.getItem("userData");
      const userDataObj = JSON.parse(userData);

      // Get the current task data
      const currentTask = taskData[filename]; // filename is the task index

      // Check if the task already has 5 images
      if (currentTask.taskPhotos.length >= 5) {
        message.error("A maximum of 5 images can be uploaded for this task.");
        onError(new Error("Maximum number of images reached"));
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}resources/upload`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userDataObj.token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Upload failed");
      }

      const data = await response.json();
      // message.success("File uploaded successfully!");

      // Update taskPhotos for the specific task
      setTaskData((prevTaskData) => {
        const updatedTaskData = [...prevTaskData];
        updatedTaskData[filename] = {
          ...updatedTaskData[filename],
          taskPhotos: [
            ...updatedTaskData[filename].taskPhotos,
            data.data?.imageUrl,
          ],
        };
        return updatedTaskData;
      });

      onSuccess(data);
    } catch (error) {
      message.error("Upload failed. Please try again.");
      onError(error);
    }
  };

  const handleRemove = (file, taskIndex) => {
    setTaskData((prev) => {
      const updatedTaskData = [...prev];
      updatedTaskData[taskIndex] = {
        ...updatedTaskData[taskIndex],
        taskPhotos: updatedTaskData[taskIndex].taskPhotos.filter(
          (photo) => photo !== file.response?.data?.imageUrl
        ),
      };
      return updatedTaskData;
    });
  };

  const handleRadioChange = (value, index) => {
    setRadioSelections((prev) => ({
      ...prev,
      [index]: value,
    }));
    updateScheduleText(index);
  };

  // useEffect(() => {
  //   taskData.forEach((task, index) => {
  //     if (task?.taskFrequency === "YEARLY") {
  //       setRadioSelections((prev) => ({
  //         ...prev,
  //         [index]: "On Day",
  //       }));
  //     }
  //   });

  // }, []);

  useEffect(() => {
    taskData.forEach((task, index) => {
      updateScheduleText(index);
    });
  }, [taskData, radioSelections]);

  return (
    <div
      className="relative task-div  pt-[8px] px-[8px] overflow-auto"
      style={{ height: "calc(100vh - 75px)" }}
    >
      {contextHolder}
      <TimeConflictModal
        open={open}
        modalContent={modalContent}
        onCancel={handleCancel}
        loading={loading}
      />
      <ProcedureModal
        open={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onCancel={handleProcedureCancel}
        selectedHome={selectedHome}
        fetchProcedure={fetchProcedure}
        openNotification={openNotification}
      />
      <div className="flex flex-col gap-2 bg-[#F2F2F2] px-2 py-2 rounded-lg">
        {taskData?.map((task, index) => {
           const showError = errorTasks.includes(index === 0 ? 1 : 0);
          return (
            <div
              id={`task-${index}`}
              key={index}
              className={`w-full add-task-form m-auto bg-white rounded-lg  ${
                showError ? "task-error" : ""
              }
            `}
            >
              <div className="task-details">
                <h2 className="text-left mb-[10px] text-[14px] font-light tracking-[1.1px]">
                  TASK DETAILS
                </h2>
                <div className="flex  flex-col gap-2">
                  <div className="flex gap-[10px]">
                    <div className="w-[50%]">
                      <div className="input-group">
                        <label className=" text-left text-[12px] font-medium text-[#262626]">
                          Task Title<span className="text-red-600">*</span>
                        </label>
                        <div className="leading-3">
                          <Input
                            className="w-[100%] h-[30px]"
                            required
                            style={{
                              border: taskErrors[index]?.title
                                ? "1px solid #F44336"
                                : "",
                            }}
                            variant="filled"
                            type="text"
                            name="title"
                            value={task.title}
                            onChange={(e) => {
                              handleInputChange(
                                e.target.value,
                                e.target.name,
                                index
                              );
                            }}
                            placeholder="Type here..."
                            maxLength={200}
                            showCount
                          />
                          {taskErrors[index]?.title && (
                            <span className="text-[#F44336] text-[11px]">
                              Title is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-[50%]">
                      <div className="input-group">
                        <label className="text-left text-[12px] font-bold text-[#262626]">
                          Procedure
                        </label>
                        <div className="flex items-center w-[100%]">
                          <Select
                            suffixIcon={
                              <DownArrow style={{ height: 12, width: 12 }} />
                            }
                            // size="middle"
                            className="h-[30px] w-[200px]"
                            variant="filled"
                            allowClear
                            // style={{ width: "200px" }}
                            placeholder="Select Procedure"
                            value={task.procedureId}
                            onChange={(value) =>
                              handleInputChange(value, "procedureId", index)
                            }
                            dropdownRender={(menu) => (
                              <>
                                <div
                                  className="flex items-center text-left "
                                  onMouseDown={(e) => e.preventDefault()} // Prevent dropdown close
                                >
                                  <Button
                                    onClick={showModal}
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      margin: "0",
                                      justifyContent: "start",
                                      textAlign: "left",
                                      color: "black",
                                    }}
                                  >
                                    Create New Procedures
                                  </Button>
                                </div>
                                {menu}
                              </>
                            )}
                          >
                            {procedure.map((item, index) => (
                              <Select.Option key={index} value={item.id}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {task.procedureSteps.length > 0 ? (
                    <div className="flex flex-col text-left w-[70%]">
                      <label className=" text-left text-[12px] font-medium text-[#262626]">
                        Additional Notes
                      </label>
                      <div
                        style={{
                          borderRadius: "4px",
                          padding: "2px 5px",
                          // height: "148px",
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "#0000000a",
                        }}
                      >
                        <div
                          style={{
                            overflowY: "auto",
                            maxHeight: "100px", // Adjust this for scrollable height
                            marginBottom: "8px",
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: "12px",
                          }}
                          className="custom-scrollbar"
                        >
                          {task.procedureSteps.map((step, index) => (
                            <p
                              key={index}
                              style={{
                                margin: "0px",
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-all",
                                textAlign: "left",
                                fontSize: "14px",
                                alignItems: "center",
                                gap: "12px",
                                display: "flex",
                              }}
                            >
                              {
                                <div className="h-[5px] w-[5px] rounded-full bg-[#205368] ring-[#205368] ring-[2px]"></div>
                              }{" "}
                              {step}
                            </p>
                          ))}
                        </div>

                        <div className="">
                          <label className="text-left text-[12px] font-medium text-[#262626]">
                            Task Description
                          </label>
                          <TextArea
                            name="description"
                            className="custom-scrollbar"
                            style={{
                              backgroundColor: "#0000000a",
                              width: "100%",
                              border: "none",
                              height: "20px",
                            }}
                            // size="middle"
                            // autoSize={{ minRows: 1.5, maxRows: 5 }}
                            value={task.description}
                            onChange={(e) =>
                              handleInputChange(
                                e.target.value,
                                e.target.name,
                                index
                              )
                            }
                            placeholder="Type here..."
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col text-left w-[70%]">
                      <label className="text-left text-[12px] font-medium text-[#262626]">
                        Task Description
                      </label>
                      <TextArea
                        name="description"
                        className="custom-scrollbar"
                        style={{
                          backgroundColor: "#0000000a",
                          width: "100%",
                          border: "none",
                          height: "20px",
                        }}
                        // size="middle"
                        // autoSize={{ minRows: 1.5, maxRows: 5 }}
                        value={task.description}
                        onChange={(e) =>
                          handleInputChange(
                            e.target.value,
                            e.target.name,
                            index
                          )
                        }
                        placeholder="Type here..."
                      />
                    </div>
                  )}
                  <div className="flex gap-[10px]">
                    <div className="w-[50%]">
                      <div className="input-group ">
                        <label className="text-left text-[12px] font-bold text-[#262626]">
                          Assign To<span className="text-red-600">*</span>
                        </label>
                        <div className="leading-3">
                          {staffList.length > 0 ? (
                            <Select
                              suffixIcon={
                                <DownArrow style={{ height: 12, width: 12 }} />
                              }
                              className="w-[100%] h-[30px] rounded-md"
                              variant="filled"
                              style={{
                                border: taskErrors[index]?.assignedToUserId
                                  ? "1px solid #F44336"
                                  : "",
                              }}
                              name="assignee"
                              value={task.assignedToUserId}
                              onChange={(value) =>
                                handleInputChange(
                                  value,
                                  "assignedToUserId",
                                  index
                                )
                              }
                              placeholder="Select assignee"
                            >
                              {staffList
                                .filter((item) => item.isEnabled)
                                .map((item, index) => (
                                  <Select.Option
                                    key={index}
                                    value={item.userId}
                                  >
                                    {item.user.firstName} {item.user.lastName}
                                  </Select.Option>
                                ))}
                            </Select>
                          ) : (
                            <div className="text-red-500 text-[11px]">
                              There are no Staff Member currently
                            </div>
                          )}

                          {taskErrors[index]?.assignedToUserId && (
                            <span className="text-[#F44336] text-[11px]">
                              Assigned user is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="w-[50%]">
                      <div className="input-group">
                        <label className="text-left text-[12px] text-for-this  font-bold text-[#262626]">
                          Assign To in case of Absence
                        </label>
                        <Select
                          suffixIcon={
                            <DownArrow style={{ height: 12, width: 12 }} />
                          }
                          // size="large"
                          className="w-[100%] h-[30px]"
                          variant="filled"
                          name="backupAssignee"
                          value={task.assignedToSubUserId}
                          onChange={(value) =>
                            handleInputChange(
                              value,
                              "assignedToSubUserId",
                              index
                            )
                          }
                          placeholder="Select backup assignee"
                          disabled={
                            staffList.filter(
                              (item) =>
                                item.userId !== task.assignedToUserId &&
                                item.isEnabled
                            ).length === 0
                          }
                        >
                          {staffList
                            .filter(
                              (item) =>
                                item.userId !== task.assignedToUserId &&
                                item.isEnabled
                            )
                            .map((item, index) => (
                              <Select.Option key={index} value={item.userId}>
                                {item.user.firstName} {item.user.lastName}
                              </Select.Option>
                            ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-[1px] bg-[#D2D4D5]"></div>

              <div className="task-schedule">
                <h2 className="text-left  mb-[10px]  text-[14px] font-light tracking-[1.1px]">
                  TASK SCHEDULE
                </h2>
                <div className="flex flex-col gap-2 ">
                  {/* we have heighy on upper div */}
                  <div className="flex gap-[10px]">
                    <div className="w-[50%]">
                      <div className="input-group">
                        <label className="text-left text-[12px] font-bold text-[#262626]">
                          Set Frequency
                        </label>
                        <Select
                          suffixIcon={
                            <DownArrow style={{ height: 12, width: 12 }} />
                          }
                          // size="large"
                          className="h-[30px]"
                          variant="filled"
                          name="taskFrequency"
                          value={task.taskFrequency}
                          onChange={(value) => {
                            handleInputChange(value, "taskFrequency", index);
                            updateScheduleText(index);
                          }}
                        >
                          {taskSchedule.map((item) => (
                            <Select.Option key={item.value} value={item.value}>
                              {item.label.toUpperCase()}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    </div>

                    <div className="w-[50%]">
                      {task?.taskFrequency === "DOES_NOT_REPEAT" && (
                        <div className="input-group">
                          <label className="text-left text-[12px] font-bold text-[#262626]">
                            Choose Date<span className="text-red-600">*</span>
                          </label>
                          <div className="flex flex-col leading-3">
                            <DatePicker
                              allowClear={true} // Enable clear functionality
                              defaultValue={dayjs().format("DD-MM-YYYY")}
                              suffixIcon={
                                <Calender style={{ height: 14, width: 14 }} />
                              }
                              className="h-[30px]"
                              variant="filled"
                              format={{
                                format: 'DD-MM-YYYY',
                                type: 'mask',
                              }}
                              style={{
                                border: taskErrors[index]?.startDate
                                  ? "1px solid #F44336"
                                  : "",
                              }}
                              value={
                                task?.taskSchedule[0]?.startDate
                                  ? dayjs(
                                      task?.taskSchedule[0]?.startDate,
                                      "DD-MM-YYYY"
                                    )
                                  : null // If there's no startDate, set it to null for clearing
                              }
                              disabledDate={(current) =>
                                current && current.isBefore(dayjs(), "day")
                              } // Disable past dates
                              onChange={(date, dateString) =>
                                handleInputChange(
                                  dateString,
                                  "taskSchedule",
                                  index,
                                  "startDate"
                                )
                              }
                              onClear={() => {
                                // Clear the date value when the user clicks the clear button
                                handleInputChange(
                                  "",
                                  "taskSchedule",
                                  index,
                                  "startDate"
                                );
                              }}
                              placeholder="DD-MM-YYYY" // Placeholder to show the expected date format
                            />
                            {taskErrors[index]?.startDate && (
                              <span className="text-[#F44336] text-[11px]">
                                Date is required
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="py-1 flex flex-col gap-2 max-h-[160px] h-fit w-[100%] custom-scrollbar overflow-auto">
                    {task?.taskFrequency === "EVERY_WORK_DAY" && (
                      <EveryWorkDay
                        taskErrors={taskErrors}
                        taskData={task}
                        handleInputChange={handleInputChange}
                        index={index}
                      />
                    )}

                    {task?.taskFrequency === "DAILY" && (
                      <Daily
                        taskErrors={taskErrors}
                        taskData={task}
                        handleInputChange={handleInputChange}
                        index={index}
                      />
                    )}
                    {task?.taskFrequency === "WEEKLY" && (
                      <Weekly
                        taskErrors={taskErrors}
                        taskData={task}
                        handleInputChange={handleInputChange}
                        index={index}
                        error ={taskErrors}
                      />
                    )}

                    {task?.taskFrequency === "MONTHLY" && (
                      <Monthly
                        taskErrors={taskErrors}
                        taskData={task}
                        handleInputChange={handleInputChange}
                        handleRadioChange={(value) =>
                          handleRadioChange(value, index)
                        }
                        isRadioSelected={radioSelections[index] || "On Day"}
                        index={index}
                        error ={taskErrors}
                      />
                    )}

                    {task?.taskFrequency === "YEARLY" && (
                      <Yearly
                        taskErrors={taskErrors}
                        taskData={task}
                        handleInputChange={handleInputChange}
                        handleRadioChange={(value) =>
                          handleRadioChange(value, index)
                        }
                        isRadioSelected={radioSelections[index] || "On Day"}
                        index={index}
                        error ={taskErrors}
                      />
                    )}

                    {task?.taskFrequency === "CUSTOM" && (
                      <Custom
                        taskErrors={taskErrors}
                        taskData={task}
                        handleInputChange={handleInputChange}
                        addNewSchedule={() => addNewSchedule(index)}
                        index={index}
                      />
                    )}

                    {task?.taskFrequency !== "CUSTOM" && (
                      <div className="flex gap-[10px]">
                        <div className="input-group">
                          <label className="text-left text-[12px] font-bold text-[#262626]">
                            Start Time
                          </label>
                          <div className="relative h-[30px] flex items-center  rounded-md bg-[#f5f5f5]">
                            {/* Input Element */}
                            <input
                              type="time"
                              className="h-full bg-[#f5f5f5] border-none  rounded pl-4 text-[14px] text-[#262626] w-full appearance-none outline-none"
                              value={
                                task?.taskSchedule[0]?.startTime
                                  ? `${String(
                                      Math.floor(
                                        task.taskSchedule[0].startTime / 100
                                      )
                                    ).padStart(2, "0")}:${String(
                                      task.taskSchedule[0].startTime % 100
                                    ).padStart(2, "0")}`
                                  : ""
                              }
                              onChange={(e) => {
                                // Convert back to your custom time format
                                handleInputChange(
                                  e.target.value,
                                  "taskSchedule",
                                  index,
                                  "startTime"
                                );
                              }}
                            />
                            <div
                              onClick={() => {
                                handleInputChange(
                                  "",
                                  "taskSchedule",
                                  index,
                                  "startTime"
                                ); // Clear on click
                              }}
                              className=" mb-0.5 ml-auto mr-3 cursor-pointer"
                            >
                              {/* You can replace this with an actual icon (like an SVG or an icon font) */}
                              {task?.taskSchedule[0]?.startTime ?(
                              <span className="text-gray-500 text-[20px]">×</span>
                              ):""}  
                            </div>
                          </div>
                        </div>
                        <div className="input-group">
                          <label className="text-left text-[12px] font-bold text-[#262626]">
                            End Time
                          </label>
                          <div className="relative h-[30px] flex items-center  rounded-md bg-[#f5f5f5]">
                            {/* Input Element */}
                            <input
                              type="time"
                              className="h-full bg-[#f5f5f5] border-none  rounded pl-4 text-[14px] text-[#262626] w-full appearance-none outline-none"
                              value={
                                task?.taskSchedule[0]?.endTime
                                  ? `${String(
                                      Math.floor(
                                        task.taskSchedule[0].endTime / 100
                                      )
                                    ).padStart(2, "0")}:${String(
                                      task.taskSchedule[0].endTime % 100
                                    ).padStart(2, "0")}`
                                  : ""
                              }
                              onChange={(e) => {
                                // Convert back to your custom time format
                                handleInputChange(
                                  e.target.value,
                                  "taskSchedule",
                                  index,
                                  "endTime"
                                );
                              }}
                            />
                            <div
                              onClick={() => {
                                handleInputChange(
                                  "",
                                  "taskSchedule",
                                  index,
                                  "endTime"
                                ); // Clear on click
                              }}
                              className=" mb-0.5 ml-auto mr-3 cursor-pointer"
                            >
                              {/* You can replace this with an actual icon (like an SVG or an icon font) */}
                              {task?.taskSchedule[0]?.endTime && (
                              <span className="text-gray-500 text-[20px]">×</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="schedule-text text-gray-600 text-[12px] pt-2 flex m-auto justify-center">
                  {scheduleText[index]}
                </div>
              </div>
              <div className="w-[1px] bg-[#D2D4D5]"></div>

              <div className="additional-details">
                <h2 className="text-left flex items-center gap-2 underline mb-[10px]  text-[14px] font-medium tracking-[1.1px] text-[#205368]">
                  Additional Details{" "}
                  <Tooltip
                    className="cursor-pointer"
                    placement="leftTop"
                    title="Select or add description, photos and other details"
                    
                    overlayStyle={{
                      color: "white",
                      fontSize: "12px"
                    }}
                  >
                    <CgDanger size={19} />
                  </Tooltip>
                </h2>

                <div className="flex flex-col gap-2">
                  {/* <div className="input-group">
                    <label className="text-left text-[12px] font-bold text-[#262626]">
                      Add Location
                    </label>
                    <Input
                      // size="large"
                      className="h-[30px]"
                      variant="filled"
                      type="text"
                      name="localtion"
                      value={task.locationAddress}
                      onChange={(e) =>
                        handleInputChange(e.target.value, e.target.name, index)
                      }
                      placeholder="Type or Select location"
                    />
                  </div> */}

                  <div className="image-size-foruploadimages">
                    <div className="input-group">
                      <label className="text-left text-[12px] font-bold text-[#262626]">
                        Upload Photos
                      </label>
                      <p className="text-[12px] text-[#616161]">
                        Maximum 5 photos can be uploaded.
                      </p>
                      <Upload
                        multiple
                        className=""
                        action={null} // Disable default upload
                        listType="picture-circle"
                        fileList={fileLists[index] || []}
                        customRequest={(options) =>
                          customUpload({ ...options, filename: index })
                        }
                        showUploadList={{
                          showPreviewIcon: false,
                        }}
                        onChange={handleChange(index)}
                        maxCount={5}
                        onRemove={(file) => handleRemove(file, index)}
                        accept="image/*"
                      >
                        {task?.taskPhotos?.length < 5 && (
                          <div clas>{uploadButton}</div>
                        )}
                      </Upload>
                    </div>
                  </div>

                  <div className="checkboxes">
                    {task?.taskFrequency !== "DOES_NOT_REPEAT" && (
                      <label className="flex font-normal text-[12px] flex-row gap-1 text-[#616161]">
                        <Input
                          variant="filled"
                          className="w-auto"
                          type="checkbox"
                          name="requireSkippedCompletion"
                          checked={task?.isCompletionRequired}
                          onChange={(e) =>
                            setTaskData((prevData) =>
                              prevData.map((item, idx) =>
                                idx === index
                                  ? {
                                      ...item,
                                      isCompletionRequired: e.target.checked,
                                    }
                                  : item
                              )
                            )
                          }
                        />
                        Require completion if skipped
                      </label>
                    )}
                    <label className="font-normal text-[12px] mt-[2px] flex flex-row  gap-1 text-[#616161]">
                      <Input
                        variant="filled"
                        className="w-auto"
                        type="checkbox"
                        name="requireCompletionPhoto"
                        checked={task?.isCompletionPictureRequired}
                        onChange={(e) =>
                          setTaskData((prevData) =>
                            prevData.map((item, idx) =>
                              idx === index
                                ? {
                                    ...item,
                                    isCompletionPictureRequired:
                                      e.target.checked,
                                  }
                                : item
                            )
                          )
                        }
                      />
                      <span>Require completion photo</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="bottom-4 left-1/2 transform mt-[20px] mb-[20px] justify-center flex flex-row gap-5">
        <button
          type="submit"
          onClick={(e) => handleSubmit(e, "create")}
          className="submit-button py-[4px]  text-[16px]"
        >
          Save and finish
        </button>
        <button
          type="submit"
          onClick={(e) => handleSubmit(e, "addMore")}
          className="submit-button py-[4px] text-[16px]"
        >
          Save and add more
        </button>
      </div>
    </div>
  );
};

export default AddTask;
