import {
  Button,
  DatePicker,
  Input,
  Modal,
  Space,
  Spin,
  TimePicker,
} from "antd";
import dayjs from "dayjs";
import { ReactComponent as Calender } from "../images/calendar-icon.svg";
import { ReactComponent as TimeIcon } from "../images/time-icon.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";
import moment from "moment";
import { IoMdCheckmark } from "react-icons/io";
import { AiOutlineDelete } from "react-icons/ai";
import { PlusOutlined } from "@ant-design/icons";
import { FaPlus } from "react-icons/fa";

export const GenericDatePicker = ({
  endDate,
  label,
  value,
  format = "DD-MM-YYYY",
  onChange,
  required = false,
  showRequired,
}) => {
  return (
    <div className="input-group">
      {label && (
        <label className="text-left text-[12px] text-[#262626]">
          {label}
          {required && <span className="text-red-600">*</span>}
        </label>
      )}
      <DatePicker
        style={{ border: showRequired ? "1px solid #F44336" : "" }}
        allowClear={true} // Enable clear functionality
        defaultValue={endDate ? null : dayjs()} // If endDate is provided, set the default value to null, otherwise set to current date
        suffixIcon={
          <Calender style={{ height: 14, width: 14, color: "black" }} />
        }
        className="h-[30px]"
        variant="filled"
        format={{
          format: "DD-MM-YYYY",
          type: "mask",
        }} // Date format
        value={value ? dayjs(value, format) : null} // If value is provided, use it; otherwise, set to null
        onChange={(date, dateString) => onChange(date, dateString)}
        onClear={() => {
          // Handle clearing the date
          onChange(null, ""); // Pass null or empty string when the date is cleared
        }}
        disabledDate={(current) => current && current.isBefore(dayjs(), "day")} // Disable past dates
        placeholder={format} // Placeholder shows the expected format
      />
    </div>
  );
};

export const GenericTimePicker = ({
  label,
  value,
  format = "HH:mm",
  onChange,
  required = false,
}) => {
  return (
    <div className="input-group">
      {label && (
        <label className="text-left text-[12px] text-[#262626]">
          {label}
          {required && "*"}
        </label>
      )}
      <div className="relative h-[30px] flex items-center">
        <input
          type="time"
          // size="large"
          className="h-full bg-[#0000000a] border  rounded pl-4 text-[14px] text-[#262626] w-full appearance-none outline-none"
          format={format}
          value={value}
          onChange={onChange}
          placeholder="HH:MM"
        />
      </div>
    </div>
  );
};

const TimeConflictModal = ({ open, modalContent, onCancel, loading }) => {
  const [conflictingTaskDetails, setConflictingTaskDetails] = useState(null);
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [staffRole, setStaffRole] = useState(null);
  const [schedule, setSchedule] = useState(null);

  function formatTime(startTime) {
    const hours = Math.floor(startTime / 100); // Extract hours
    const minutes = startTime % 100; // Extract minutes
    const period = hours >= 12 ? "PM" : "AM"; // Determine AM/PM
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes.toString().padStart(2, "0"); // Add leading zero if needed

    return `${formattedHours}:${formattedMinutes} ${period}`;
  }

  const fetchConflictingTaskDetails = async () => {
    setFetchingDetails(true);
    try {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const selectedHome = JSON.parse(localStorage.getItem("selectedHome"));
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/tasks/${modalContent.conflictingTaskId}?homeId=${selectedHome.id}`,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      setConflictingTaskDetails(data?.data?.task);
      setSchedule(data?.data?.task?.taskSchedule[0]);
      setStaffRole(selectedHome?.members[0]?.role);
      setFetchingDetails(false);
    } catch (error) {
      console.error("Error fetching conflicting task details:", error);
    } finally {
      setFetchingDetails(false);
    }
  };

  // useEffect(() => {
  //   if (open && modalContent.conflictingTaskId) {
  //     fetchConflictingTaskDetails();
  //   }
  // }, [open, modalContent.conflictingTaskId]);
  const modalStyles = {
    footer: {
      display: "flex",
      justifyContent: "end",
    },
    mask: {
      backdropFilter: "blur(10px)",
    },
  };

  function formateDate(date) {
    return moment(date).format("DD MMM, YYYY");
  }
  let newScheduleText = "";
  if (conflictingTaskDetails) {
    const frequency = schedule?.frequency; // e.g., Monthly, Yearly, etc.
    const onDay = schedule.onDays?.[0];
    const nthDay = schedule.onNthDaysOfWeek?.[0];
    const weekDay = schedule.onWeekDays?.[0];
    const weekDays = schedule.onWeekDays || [];
    const interval = schedule.interval;
    const month = schedule.onMonths?.[0];

    const formattedDate = moment(schedule?.startDate).format("DD MMM, YYYY");
    const formattedEndDate = schedule?.endDate
      ? moment(schedule?.endDate).format("DD MMM, YYYY")
      : "";

    if (conflictingTaskDetails.taskFrequency === "YEARLY") {
      if (onDay) {
        newScheduleText = `Occurs  every ${
          interval === 1 ? "" : interval
        } year on day ${onDay} of ${
          month.charAt(0).toUpperCase() + month.slice(1).toLowerCase()
        } starting from ${formattedDate} ${
          formattedEndDate ? `to ${formattedEndDate}` : ``
        }`;
      } else {
        newScheduleText = `Occurs  every ${
          interval === 1 ? "" : interval
        } year on the ${nthDay} ${weekDays} of ${
          month.charAt(0).toUpperCase() + month.slice(1).toLowerCase()
        }   starting from ${formattedDate} ${
          formattedEndDate ? `to ${formattedEndDate}` : ``
        }`;
      }
    }
    if (conflictingTaskDetails.taskFrequency === "MONTHLY") {
      newScheduleText = `Occurs on ${
        onDay
          ? `day ${onDay} of every ${interval === 1 ? "" : interval} month`
          : `the ${nthDay} ${weekDays} of every ${
              interval === 1 ? "" : interval
            } month`
      } starting from ${formattedDate} ${
        formattedEndDate ? ` to ${formattedEndDate}` : ""
      }`;
      newScheduleText = `Occurs on ${
        onDay
          ? `day ${onDay} of every ${interval === 1 ? "" : interval} month`
          : `the ${nthDay} ${weekDays} of every ${
              interval === 1 ? "" : interval
            } month`
      } starting from ${formattedDate} ${
        formattedEndDate ? ` to ${formattedEndDate}` : ""
      }`;
    }
    if (conflictingTaskDetails.taskFrequency === "DAILY") {
      newScheduleText = `Occurs every ${
        interval === 1 ? "" : interval
      } day starting from ${formattedDate} ${
        formattedEndDate ? `to ${formattedEndDate}` : ``
      }`;
    }
    if (conflictingTaskDetails.taskFrequency === "WEEKLY") {
      const weekDaysText = weekDays
        .map((day) => {
          return day.charAt(0).toUpperCase() + day.slice(1).toLowerCase();
        })
        .join(", ");
      newScheduleText = `Occurs every ${
        interval === 1 ? "" : interval
      } week on ${weekDaysText} starting from ${formattedDate} ${
        formattedEndDate ? `to ${formattedEndDate}` : ``
      }`;
      newScheduleText = `Occurs every ${
        interval === 1 ? "" : interval
      } week on ${weekDaysText} starting from ${formattedDate} ${
        formattedEndDate ? `to ${formattedEndDate}` : ``
      }`;
    }
    if (conflictingTaskDetails.taskFrequency === "EVERY_WORK_DAY") {
      newScheduleText = `Occurs every work day starting from ${formattedDate} ${
        formattedEndDate ? `to ${formattedEndDate}` : ``
      }`;
    }
    if (conflictingTaskDetails.taskFrequency === "DOES_NOT_REPEAT") {
      newScheduleText = `on ${formattedDate} ${
        formattedEndDate ? `to ${formattedEndDate}` : ``
      }`;
    }

    // if (conflictingTaskDetails.taskFrequency === "CUSTOM") {
    //   const formattedDate = moment(
    //     conflictingTaskDetails?.taskSchedule[0].startDate
    //   ).format("DD MMM, YYYY");
    //   const formattedEndDate = conflictingTaskDetails?.taskSchedule[
    //     conflictingTaskDetails?.taskSchedule.length - 1
    //   ].startDate
    //     ? moment(
    //         conflictingTaskDetails?.taskSchedule[
    //           conflictingTaskDetails?.taskSchedule.length - 1
    //         ].startDate
    //       ).format("DD MMM, YYYY")
    //     : "";
    //   newScheduleText = `Occurs on ${formattedDate} ${
    //     formattedEndDate ? `, ${formattedEndDate}` : ``
    //   }`;
    // }
        if (conflictingTaskDetails.taskFrequency === "CUSTOM") {
          if (conflictingTaskDetails?.taskSchedule?.length > 0) {
            const formattedDates = conflictingTaskDetails.taskSchedule.map((schedule) =>
              moment(schedule.startDate).format("DD MMM, YYYY")
            );
    
            newScheduleText = `Occurs on ${formattedDates.join(", ")}`;
          }
        }
  }

  return (
    <Modal
      width={700}
      open={open}
      styles={modalStyles}
      title={conflictingTaskDetails ? "" : modalContent.title}
      onCancel={() => {
        onCancel();
        setConflictingTaskDetails(null);
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            onCancel();
            setConflictingTaskDetails(null);
          }}
        >
          Cancel
        </Button>,
        <Button
          type="primary"
          className={`${conflictingTaskDetails ? "hidden" : "block"}`}
          loading={loading}
          onClick={() => {
            fetchConflictingTaskDetails();
          }}
        >
          Go to Task
        </Button>,
      ]}
    >
      <p>{conflictingTaskDetails ? "" : modalContent.body}</p>
      {fetchingDetails ? (
        <Spin />
      ) : conflictingTaskDetails ? (
        <div className="px-4">
          <div className="flex flex-col gap-4 pb-6">
            <h1 className="text-2xl text-slate-800 text-center font-bold">
              Task Details
            </h1>
            {loading ? (
              <div className="text-start flex flex-col md:flex-row justify-center max-w-[1000px] w-[100%] mx-auto py-4">
                <div className="flex flex-col gap-8 md:w-[50%] w-[100%] md:px-4 px-1">
                  {/* Title */}
                  <div className="h-[30px] w-[70%] bg-gray-300 rounded-md animate-pulse"></div>
                  {/* Task Status */}
                  <div className="h-[20px] w-[50%] bg-gray-300 rounded-md animate-pulse"></div>
                  {/* Assigned To */}
                  <div className="h-[20px] w-[80%] bg-gray-300 rounded-md animate-pulse"></div>
                  {/* Task Schedule */}
                  <div className="h-[20px] w-[60%] bg-gray-300 rounded-md animate-pulse"></div>
                  {/* Last Completion Date */}
                  <div className="h-[20px] w-[40%] bg-gray-300 rounded-md animate-pulse"></div>
                  {/* Start Time */}
                  <div className="h-[20px] w-[50%] bg-gray-300 rounded-md animate-pulse"></div>
                  {/* End Time */}
                  <div className="h-[20px] w-[50%] bg-gray-300 rounded-md animate-pulse"></div>
                  {/* Next Due Date */}
                  <div className="h-[20px] w-[40%] bg-gray-300 rounded-md animate-pulse"></div>
                  {/* Additional Details */}
                  <div className="h-[20px] w-[60%] bg-gray-300 rounded-md animate-pulse"></div>
                  {/* Task Description */}
                  <div className="h-[40px] w-[90%] bg-gray-300 rounded-md animate-pulse"></div>
                </div>
                <div className="w-[1px] bg-gray-200"></div>
                <div className="flex flex-col gap-8 md:w-[50%] w-[100%] md:px-4 px-1">
                  {/* Procedure Steps */}
                  <div className="h-[100px] w-[90%] bg-gray-300 rounded-md animate-pulse"></div>
                  {/* Assign to in case of absence */}
                  <div className="h-[20px] w-[70%] bg-gray-300 rounded-md animate-pulse"></div>
                  {/* Require completion Picture */}
                  <div className="flex flex-row items-center gap-2">
                    <div className="w-[18px] h-[18px] bg-gray-300 rounded-sm animate-pulse"></div>
                    <div className="h-[20px] w-[50%] bg-gray-300 rounded-md animate-pulse"></div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-start flex flex-col md:flex-row justify-center  max-w-[1000px] w-[100%] mx-auto  py-4">
                <div className="flex flex-col gap-8 md:w-[50%] w-[100%] md:px-4  px-1">
                  <h1 className="text-[20px] font-bold">
                    {conflictingTaskDetails?.title}
                  </h1>
                  <h1 className="text-[18px] font-normal text-gray-600 ">
                    Task Status:{" "}
                    <span
                      className={`${
                        conflictingTaskDetails?.taskStatus === "completed"
                          ? "text-[#205368]"
                          : conflictingTaskDetails?.taskStatus === "pending"
                          ? "text-[#DE9C36]"
                          : "text-[#DE9C36]"
                      }`}
                    >
                      {conflictingTaskDetails &&
                      conflictingTaskDetails?.taskStatus === "scheduled"
                        ? "Pending"
                        : conflictingTaskDetails?.taskStatus
                            .charAt(0)
                            .toUpperCase() +
                          conflictingTaskDetails?.taskStatus
                            .slice(1)
                            .toLowerCase()}
                    </span>
                  </h1>
                  <h1 className="text-[18px] font-normal text-gray-600">
                    Assigned to:{" "}
                    <span className="text-[18px] font-[400] text-gray-800">
                      {conflictingTaskDetails?.assignedToUser?.firstName}{" "}
                      {conflictingTaskDetails?.assignedToUser?.lastName}
                    </span>
                  </h1>
                  {conflictingTaskDetails?.taskType === "RECURRING" && (
                    <h1 className="text-[18px] font-normal text-gray-600">
                      Task Frequency:
                      <span className="text-[18px] font-[400] text-gray-800">
                        {" "}
                        {conflictingTaskDetails?.taskFrequency
                          .charAt(0)
                          .toUpperCase() +
                          conflictingTaskDetails?.taskFrequency
                            .slice(1)
                            .toLowerCase()}
                      </span>
                    </h1>
                  )}
                  <h1 className="text-[18px] font-normal text-gray-600">
                    Task Schedule:
                    <span className="text-[18px] font-[400] text-gray-800">
                      {" "}
                      {newScheduleText}
                    </span>
                  </h1>
                  {conflictingTaskDetails?.taskType === "RECURRING" &&
                    conflictingTaskDetails?.lastCompletion && (
                      <h1 className="text-[18px] font-normal text-gray-600">
                        Last completion date:{" "}
                        {conflictingTaskDetails?.lastCompletion
                          ? formateDate(conflictingTaskDetails?.lastCompletion)
                          : "--"}
                      </h1>
                    )}

                  <h1 className="text-[18px] font-normal text-gray-600">
                    Start time:{" "}
                    <span className="text-[18px] font-[400] text-gray-800">
                      {conflictingTaskDetails?.startTime
                        ? formatTime(conflictingTaskDetails?.startTime)
                        : "--/--"}
                    </span>
                  </h1>

                  <h1 className="text-[18px] font-normal text-gray-600">
                    End time:{" "}
                    <span className="text-[18px] font-[400] text-gray-800">
                      {conflictingTaskDetails?.endTime
                        ? formatTime(conflictingTaskDetails?.endTime)
                        : "--/--"}
                    </span>
                  </h1>
                  {conflictingTaskDetails?.nextOccurance && (
                    <h1 className="text-[18px] font-[500] text-gray-700">
                      Next due date:{" "}
                      {conflictingTaskDetails?.nextOccurance
                        ? formateDate(conflictingTaskDetails?.nextOccurance)
                        : "--"}
                    </h1>
                  )}
                </div>
                <div className="w-[1px]  bg-gray-200"></div>
                <div className="flex flex-col gap-4 md:w-[50%] w-[100%] md:px-4  px-1">
                  <h1 className="text-[18px] font-[600] text-black">
                    Additional Details:
                  </h1>
                  {staffRole && staffRole === "Admin" && (
                    <h1 className="text-[18px] font-normal text-gray-600">
                      Assigned by:{" "}
                      <span className="text-[18px] font-[400] text-gray-800">
                        {conflictingTaskDetails?.createdByUser?.firstName}{" "}
                        {conflictingTaskDetails?.createdByUser?.lastName}
                      </span>
                    </h1>
                  )}
                  {conflictingTaskDetails?.description && (
                    <h1 className="text-[18px] font-[600] text-black ">
                      Task Description:{" "}
                      <span className="text-[18px] font-[400] text-gray-800">
                        {conflictingTaskDetails?.description}
                      </span>
                    </h1>
                  )}
                  {conflictingTaskDetails?.procedureSteps &&
                    conflictingTaskDetails?.procedureSteps.length > 0 && (
                      <div className="mt-2 h-fit max-h-[250px] overflow-y-auto border-r pl-2 cards-scroll-container">
                        <ul className="list-disc list-inside">
                          {conflictingTaskDetails.procedureSteps.map(
                            (step, index) => (
                              <li
                                key={index}
                                className="flex items-center gap-2"
                              >
                               <div className="h-[5px] w-[5px] rounded-full bg-[#205368] ring-[#205368] ring-[2px]"></div>
                                {step}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                  {conflictingTaskDetails?.assignedToSubUser && (
                    <h1 className="text-[18px] font-normal text-gray-500">
                      Assign to in case of absensce:{" "}
                      <span className="text-[18px] font-[400] text-gray-800">
                        {conflictingTaskDetails?.assignedToSubUser?.firstName}{" "}
                        {conflictingTaskDetails?.assignedToSubUser?.lastName}
                      </span>
                    </h1>
                  )}

                  {conflictingTaskDetails?.taskType === "RECURRING" && (
                    <h1 className="text-[18px] font-normal text-gray-500 flex flex-row items-center gap-2">
                      <div className="w-[18px] flex flex-col justify-center items-center h-[18px] bg-slate-800 rounded-sm border-slate-900 border-[2px]">
                        {conflictingTaskDetails?.isCompletionRequired ? (
                          <IoMdCheckmark size={18} className="text-white" />
                        ) : (
                          <RxCross2 size={18} className="text-white" />
                        )}
                      </div>{" "}
                      Require completion if Skipped
                    </h1>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </Modal>
  );
};
export const ProcedureModal = ({
  open,
  onCancel,
  setIsModalVisible,
  selectedHome,
  fetchProcedure,
  openNotification,
  procedure,
  fetchProcedureById,
  fetchProcedures,
}) => {
  const [newProcedure, setNewProcedure] = useState({
    name: "",
    steps: [""],
  });
  const [errors, setErrors] = useState({
    title: false,
    steps: [],
  });

  // Handle input change in new procedure
  useEffect(() => {
    if (procedure) {
      setNewProcedure({
        name: procedure.name || "",
        steps:
          procedure.steps && procedure.steps.length > 0
            ? procedure.steps
            : [""], // Ensure steps is always an array with at least one item
      });
    } else {
      setNewProcedure({
        name: "",
        steps: [""], // Reset to default state
      });
    }
  }, [procedure]);
  const handleNewProcedureChange = (e, field) => {
    setNewProcedure({
      ...newProcedure,
      [field]: e.target.value,
    });
  };

  // Handle adding a new step
  const handleAddStep = () => {
    setNewProcedure({
      ...newProcedure,
      steps: [...newProcedure.steps, ""],
    });
  };

  // Handle changing a specific step
  const handleStepChange = (e, index) => {
    const updatedSteps = [...newProcedure.steps];
    updatedSteps[index] = e.target.value;
    setNewProcedure({
      ...newProcedure,
      steps: updatedSteps,
    });
  };
  const handleCreateProcedure = async () => {
    try {
      const userData = localStorage.getItem("userData");
      const userDataObj = JSON.parse(userData);

      const homeId = selectedHome?.id;
      if (!validateProcedure()) {
        return; // Stop further processing if validation fails
      }

      let response;
      if (procedure) {
        // If a procedure exists, update it
        response = await axios.patch(
          `${process.env.REACT_APP_API_URL}procedures/${procedure?.id}`, // API endpoint
          {
            ...newProcedure, // Data to update
          },
          {
            headers: {
              Authorization: `Bearer ${userDataObj.token}`, // Authorization header
            },
          }
        );
        fetchProcedureById(procedure?.id);
        fetchProcedures(homeId);
      } else {
        // If no procedure exists, create a new one
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}procedures`, // API endpoint
          {
            homeId, // Include homeId in the new procedure
            ...newProcedure,
          },
          {
            headers: {
              Authorization: `Bearer ${userDataObj.token}`, // Authorization header
            },
          }
        );
        fetchProcedure(homeId);
      }

      // Fetch updated procedures after the operation
    } catch (error) {
      openNotification(
        "top",
        error?.response?.data?.error || error?.response?.data?.data?.error
      );
    }
    setIsModalVisible(false);
    resetModal();
  };
  const validateProcedure = () => {
    let hasError = false;

    // Validate title
    if (!newProcedure?.name || newProcedure?.name.trim() === "") {
      setErrors((prev) => ({ ...prev, title: true }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, title: false }));
    }

    // Validate steps
    const stepErrors = newProcedure.steps.map(
      (step) => !step || step.trim() === ""
    );
    if (stepErrors.some((error) => error)) {
      setErrors((prev) => ({ ...prev, steps: stepErrors }));
      hasError = true;
    } else {
      setErrors((prev) => ({ ...prev, steps: [] }));
    }

    return !hasError; // Returns true if no errors
  };
  const resetModal = async () => {
    setNewProcedure({
      name: "",
      steps: [""],
    });
  };
  const resetErrors = async () => {
    setErrors({
      title: false,
      steps: [],
    });
  };

  const handleCancel = async () => {
    await resetErrors(); // Reset form and errors
    onCancel();
    fetchProcedureById(procedure?.id); // Call parent onCancel logic
  };
  const handleCreateCancel = async () => {
    await resetErrors(); // Reset form and errors
    onCancel();
  };

  const handleRemoveStep = (idx) => {
    const updatedSteps = newProcedure.steps.filter((_, index) => index !== idx);
    setNewProcedure((prev) => ({
      ...prev,
      steps: updatedSteps,
    }));
  };
  const lastStepValid =
    newProcedure.steps[newProcedure.steps.length - 1]?.trim() !== "";

  return (
    <Modal
      bodyStyle={{ maxHeight: "70vh" }}
      title={procedure ? "Edit Procedure" : "Create Procedure"}
      open={open}
      onCancel={procedure ? handleCancel : handleCreateCancel}
      footer={[
        <Button
          key="back"
          onClick={procedure ? handleCancel : handleCreateCancel}
          style={{ color: "black", borderColor: "black" }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          style={{ backgroundColor: "#205368", color: "white", border: "none" }}
          onClick={handleCreateProcedure}
        >
          {procedure ? "Save" : "Create"}
        </Button>,
      ]}
    >
      <div>
        <div>
          <label>
            Procedure Title<span className="text-red-600">*</span>
          </label>
          <Input
            value={newProcedure.name}
            style={{
              borderColor: "gray",
              boxShadow: "none",
              backgroundColor: "#0000000a",
              border: "none",
            }}
            onChange={(e) => {
              handleNewProcedureChange(e, "name");
              setErrors((prev) => ({ ...prev, title: false }));
            }}
            placeholder="Enter procedure name"
          />
          {errors.title && (
            <span style={{ color: "red", fontSize: "12px" }}>
              Procedure title is required.
            </span>
          )}
        </div>
        <div style={{ marginTop: 20 }}>
          <label>
            Steps<span className="text-red-600">* </span>
          </label>
          {errors.steps.some((error) => error) && (
            <span style={{ color: "red", fontSize: "12px" }}>
              All steps are required.
            </span>
          )}
          <div className="flex flex-col h-[450px] cards-scroll-container">
            {newProcedure.steps.map((step, idx) => (
              <Space
                key={idx}
                style={{
                  display: "flex",
                  marginBottom: 10,
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Input
                  style={{
                    width: "100%",
                    borderColor: "gray",
                    boxShadow: "none",
                    backgroundColor: "#0000000a",
                    border: "none",
                  }}
                  allowClear={true}
                  value={step}
                  onChange={(e) => {
                    handleStepChange(e, idx);
                    setErrors((prev) => {
                      const updatedSteps = [...prev.steps];
                      updatedSteps[idx] = false; // Clear error for this step
                      return { ...prev, steps: updatedSteps };
                    });
                  }}
                  placeholder={`Enter step ${idx + 1}`}
                />
                <Button
                  style={{
                    backgroundColor: "#F7EBDA",
                    border: "none",
                    display: newProcedure.steps.length === 1 ? "none" : "flex",
                  }}
                  shape="circle"
                  onClick={() => handleRemoveStep(idx)}
                >
                  <AiOutlineDelete color="#E64646" size={20} />
                </Button>
                {idx === newProcedure.steps.length - 1 && (
                  <Button
                    shape="circle"
                    style={{
                      backgroundColor: "#205368",
                      color: "white",
                      border: "none",
                    }}
                    onClick={handleAddStep}
                    disabled={!lastStepValid}
                  >
                    <FaPlus />
                  </Button>
                )}
              </Space>
            ))}
          </div>
          {/* <Button type="dashed" onClick={handleAddStep} style={{ width: '100%' }}>
            Add Step
          </Button> */}
        </div>
      </div>
    </Modal>
  );
};
export default TimeConflictModal;
