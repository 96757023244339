import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoPlayOutline } from "react-icons/io5";
import { GiCheckMark } from "react-icons/gi";
import dayjs from "dayjs";
import { MdArrowBackIos } from "react-icons/md";

import TaskCards from "../components/TaskCards/TaskCards";
import TaskDetails from "./TaskDetails";

const MyTaskById = () => {
  console.log("sdoskdso");

  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [allTaskById, setAllTaskById] = useState();

  const [staffRole, setStaffRole] = useState("");

  const url = window.location.href;
  const pathSegments = url.split("/");
  const staffTasksName = pathSegments.find(
    (segment) => segment === "staff-tasks"
  );

  const GetAllTaskById = async (token, staffUserId, homeId) => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/tasks/staff-member-tasks?homeId=${homeId}&staffUserId=${staffUserId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setAllTaskById(data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const userDataObj = JSON.parse(userData);
    const storedSelectedHome = localStorage.getItem("selectedHome");
    const selectedHome = JSON.parse(storedSelectedHome);
    setStaffRole(selectedHome?.members[0]?.role);

    if (id) {
      GetAllTaskById(userDataObj?.token, id, selectedHome?.id);
    }
  }, [id]);

  return (
    <div
      className="px-5  relative"
      style={{ height: "calc(100vh - 90px)", overflow: "auto" }}
    >
      <div className="w-fit py-5">
        {staffTasksName === "staff-tasks" ? (
          <>
            <h1
              className="text-[16px] items-center  text-black hover:text-slate-600 flex flex-row font-semibold cursor-pointer"
              onClick={() => {
                navigate("/staff-tasks");
              }}
            >
              {<MdArrowBackIos size={20} />}
              Staff Tasks
            </h1>
          </>
        ) : (
          <>
            <h1
              className="text-[16px] items-center  text-black hover:text-slate-600 flex flex-row font-semibold cursor-pointer"
              onClick={() => {
                if (staffRole !== "Staff") {
                  navigate("/my-tasks");
                }
              }}
            >
              {staffRole !== "Staff" && <MdArrowBackIos size={20} />}
              My Tasks
            </h1>
          </>
        )}
      </div>

      <div className="for-todays-task border rounded-3xl">
        <h1 className={`text-2xl  mb-5 ${"text-start"} px-5`}>Today's Task</h1>
        <TaskCards
          taskCompleteOrIncomplete={allTaskById?.incompleteTasks}
          loading={loading}
          staffTasksName={staffTasksName}
        />
        {allTaskById?.incompleteTasks?.length === 0 && (
          <div className="text-[15px] text-start px-5 flex justify-center">
            <div className="flex flex-col gap-2">
              <p className="flex items-center justify-center font-bold text-[18px]">Congratulations! 🎉</p>
              <span>All your work for the day is done!</span>
            </div>
          </div>
        )}
      </div>

      <div className="mt-4 for-completed-task border py-5 rounded-3xl">
        <h1 className={`text-xl ps-6  mb-3 ${"text-start"}`}>
          Completed Tasks ({allTaskById?.completedTasks?.length})
        </h1>
        <TaskCards
          taskCompleteOrIncomplete={allTaskById?.completedTasks}
          loading={loading}
          staffTasksName={staffTasksName}
        />

        {allTaskById?.completedTasks?.length === 0 && (
          <div className="text-[15px] text-start px-6">No Completed Tasks</div>
        )}
      </div>
    </div>
  );
};

export default MyTaskById;
